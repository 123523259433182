import React from 'react';

const FormField = ({ name, placeholder, register, error, textarea = false }) => {
    // Set the input type to 'tel' for phone and handle non-numeric restrictions
    const inputType = name === 'phone' ? 'tel' : 'text';

    // Function to prevent non-numeric input for phone field
    const handleKeyPress = (e) => {
        if (name === 'phone' && !/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    };

    return (
        <div className="form-group">
            {textarea ? (
                <textarea
                    id={name}
                    placeholder={error ? error : placeholder}
                    {...register(name)}
                    className={`form-input ${error ? 'error-border' : ''}`}
                />
            ) : (
                <input
                    id={name}
                    type={inputType}
                    placeholder={error ? error : placeholder}  // Show error message as placeholder
                    {...register(name)}
                    className={`form-input ${error ? 'error-border' : ''}`}
                    onKeyPress={handleKeyPress}  // Restrict non-numeric input for phone
                />
            )}
        </div>
    );
};

export default FormField;

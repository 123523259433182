import React, { useEffect, useRef, useState } from 'react';
import BookingForm from './BookingForm';
import './Modal.css';

const Modal = ({ isOpen, onClose, defaultDuration }) => {
    const modalRef = useRef();
    const [isSuccess, setIsSuccess] = useState(false); // State to handle success message
    const [timer, setTimer] = useState(null);
    const closeBtnRef = useRef();

    // Close modal when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    // Focus trap to improve accessibility
    useEffect(() => {
        if (isOpen) {
            closeBtnRef.current.focus();
        }
    }, [isOpen]);

    // Reset success state whenever the modal is opened
    useEffect(() => {
        if (isOpen) {
            setIsSuccess(false); // Reset success state when modal opens
        }
    }, [isOpen]);

    // Handle success message and auto-close after 3 seconds
    const handleSuccess = () => {
        setIsSuccess(true);

        // Auto-close modal after 3 seconds
        const timerId = setTimeout(() => {
            setIsSuccess(false);
            onClose();
        }, 3000);

        setTimer(timerId);
    };

    useEffect(() => {
        if (!isOpen) {
            clearTimeout(timer); // Clear timer when modal is closed
        }
    }, [isOpen, timer]);

    if (!isOpen) return null;

    return (
        <div className="modal-overlay" role="dialog" aria-labelledby="modal-title" aria-modal="true">
            <div className="modal-content" ref={modalRef}>
                <button className="close-button" ref={closeBtnRef} onClick={onClose}>
                    X
                </button>
                <h2 className="modal-title" id="modal-title">Book Now</h2>

                {isSuccess ? (
                    <div className="success-message">
                        <div className="checkmark">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle cx="26" cy="26" r="25" fill="none" />
                                <path fill="none" stroke="#4CAF50" strokeWidth="4" d="M14 27l7 7 16-16" />
                            </svg>
                        </div>
                        <h3>Booking successfully submitted!</h3>
                        <p>We will get back to you soon.</p>
                        <button onClick={onClose} className="button-style">
                            Close Now
                        </button>
                    </div>
                ) : (
                    <BookingForm defaultDuration={defaultDuration} onClose={onClose} onSuccess={handleSuccess} />
                )}
            </div>
        </div>
    );
};

export default Modal;

import React, { useState } from 'react';

const SubmitButton = ({ isSubmitting, buttonText }) => {
    const [isClicked, setIsClicked] = useState(false);

    const handleClick = () => {
        if (!isSubmitting) {
            setIsClicked(true);
            setTimeout(() => {
                setIsClicked(false);
            }, 300);
        }
    };

    return (
        <button
            type="submit"
            className={`button-style ${isClicked ? 'button-clicked' : ''}`}
            onClick={handleClick}
            disabled={isSubmitting}
            style={{
                backgroundColor: isSubmitting ? '#a57dde' : '#a57dde', // Purple background
                color: 'white', // White text
                border: '2px solid #a57dde',
                cursor: isSubmitting ? 'not-allowed' : 'pointer',
                padding: '10px 20px',
                fontSize: '16px',
                boxSizing: 'border-box',
                borderRadius: '4px',
                transition: 'background-color 0.3s ease, color 0.3s ease, transform 0.2s ease',
            }}
        >
            {isSubmitting ? 'Submitting...' : buttonText}
        </button>
    );
};

export default SubmitButton;

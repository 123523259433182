import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormField from './FormField';
import CustomDatePicker from './DatePicker';
import SubmitButton from './SubmitButton';

// Validation Schema
const schema = yup.object().shape({
    fullName: yup.string().required('Full Name is required'),
    email: yup.string().email('Invalid email format').required('Email is required'),
    phone: yup.string().required('Phone number is required'),
    message: yup.string().nullable(),
    date: yup.date().required('Date is required').nullable(),
});

const BookingForm = ({ defaultDuration, onClose, onSuccess }) => {
    const [serviceDuration, setServiceDuration] = useState(defaultDuration);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        setServiceDuration(defaultDuration);
        setValue('serviceDuration', defaultDuration);
    }, [defaultDuration, setValue]);

    const onSubmit = async (data) => {
        setIsSubmitting(true);

        const formData = {
            name: data.fullName,
            email: data.email,
            phone: data.phone,
            package: serviceDuration,
            date: data.date,
            message: data.message,
        };

        try {
            const response = await fetch(process.env.REACT_APP_API_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const result = await response.json();

            if (result.success) {
                onSuccess();
            } else {
                alert('Failed to send email. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred while sending your booking request.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
                <label htmlFor="serviceDuration">Service Duration</label>
                <select
                    name="serviceDuration"
                    value={serviceDuration}
                    onChange={(e) => setServiceDuration(e.target.value)}
                    className={`form-input ${errors.serviceDuration ? 'error-border' : ''}`}
                >
                    <option value="4 hours">4 hours</option>
                    <option value="6 hours">6 hours</option>
                    <option value="8 hours">8 hours</option>
                    <option value="Overnight">Overnight</option>
                </select>
            </div>

            <CustomDatePicker register={register} setValue={setValue} errors={errors} />

            <FormField name="fullName" placeholder="Full Name" register={register} error={errors.fullName?.message} />
            <FormField name="email" placeholder="Email" register={register} error={errors.email?.message} />
            <FormField name="phone" placeholder="Phone" register={register} error={errors.phone?.message} />
            <FormField name="message" placeholder="Message" register={register} textarea />

            <SubmitButton isSubmitting={isSubmitting} buttonText="Book" />
        </form>
    );
};

export default BookingForm;

import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CustomDatePicker = ({ register, setValue, errors }) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [minDate, setMinDate] = useState(null);

    useEffect(() => {
        const today = new Date();
        today.setDate(today.getDate() + 1); // Disable past dates including today
        setMinDate(today);
    }, []);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setValue('date', date);
    };

    const handleKeyDown = (e) => {
        e.preventDefault(); // Disable typing in the date picker input
    };

    return (
        <div className="form-group date-picker">
            <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                minDate={minDate}
                placeholderText={errors.date ? errors.date.message : "Select a date"}
                dateFormat="yyyy/MM/dd"
                className={`form-input ${errors.date ? 'error-border' : ''}`}
                onKeyDown={(e) => e.preventDefault()}  // Prevents any manual keyboard input
                readOnly  // Prevents the keyboard from opening on mobile
                showPopperArrow={false}  // Optional, removes popper arrow if unwanted
            />
        </div>
    );
};

export default CustomDatePicker;

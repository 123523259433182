import React, { useState, useEffect } from 'react';
import Modal from './components/Modal';
import './App.css';

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [defaultDuration, setDefaultDuration] = useState('4 hours');

  const openModal = (duration) => {
    setDefaultDuration(duration);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    window.openModalWithPackage = (packageName) => {
      const durationMap = {
        '4-Hour Journey': '4 hours',
        '6-Hour Adventure': '6 hours',
        '8-Hour Escape': '8 hours',
        'Overnight Retreat': 'Overnight',
      };
      const duration = durationMap[packageName] || '4 hours';
      openModal(duration);
    };

    return () => {
      delete window.openModalWithPackage;
    };
  }, []);

  return (
    <div className="App">
      <Modal isOpen={isModalOpen} onClose={closeModal} defaultDuration={defaultDuration} />
    </div>
  );
}

export default App;
